import Input from '../Fields/Input'
import { Field, Form, FormikProps } from 'formik'
import React from 'react'

export interface UpdateLostPasswordFields {
  code: string
  password: string
  passwordConfirm: string
}

interface UpdateLostPasswordFormProps extends FormikProps<UpdateLostPasswordFields> { }

const UpdateLostPasswordForm: React.FC<UpdateLostPasswordFormProps> = () => {
  return (
    <Form>
      <div className="relative w-full mb-3">

        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Code reçu par email
        </label>
        <Field
          name="code"
          placeholder="Code reçu par email"
          component={Input}
          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
      </div>

      <div className="relative w-full mb-3">

        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Nouveau mot de passe
        </label>
        <Field
          name="password"
          placeholder="Nouveau mot de passe"
          type="password"
          component={Input}
          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
      </div>

      <div className="relative w-full mb-3">

        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Confirmez le mot de passe saisie
        </label>
        <Field
          name="passwordConfirm"
          placeholder="Confirmer votre mot de passe"
          component={Input}
          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          type="password"
        />
      </div>
      <div className="text-center mt-6">
        <button
          className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
          type="submit"
        >
          Modifier le mot de passe
        </button>
      </div>
    </Form>
  )
}

export default UpdateLostPasswordForm
