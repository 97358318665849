import React, { useState } from 'react'
import f from '../../utils/fetch';
import LostPasswordForm, { LostPasswordFields } from '../../components/Forms/LostPasswordForm'
import validateLostPasswordForm from '../../components/Forms/LostPasswordForm/validate'
import { Formik, FormikHelpers } from 'formik'
import { Link, useNavigate, useNavigation } from 'react-router-dom'
import routes from '../../routes'
import Alert from '../../components/Alert'

const LostPassword: React.FC = () => {

    const navigate = useNavigate();

    const [error, setError] = useState<string | null>(null);

    const onSubmit = async (values: LostPasswordFields, formik: FormikHelpers<LostPasswordFields>) => {

        setError(null);

        try {

            const r: any = await f({ url: `/users/password-lost`, method: 'POST', data: values });

            navigate({
                pathname: routes.UPDATE_LOST_PASSWORD,
                search: `${window.location.search}&email=${values.email}`,
            });


        } catch (error: any) {
            setError(error.message);
        }

        formik.resetForm()
    }

    return (
        <div className='w-full lg:w-4/12 px-4'>
            <div className='relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0'>
                <div className="rounded-t mb-0 px-6 py-6">

                    <div style={{ fontSize: 60, textAlign: 'center', fontWeight: 900, fontFamily: 'Nunito' }} className="text-blueGray-600">
                        <img src="bleu-fond-blanc.png" style={{ width: 250, margin: 'auto' }} />
                    </div>
                    <div className="text-blueGray-400 text-center mb-3 font-bold"><small>Mot de passe perdu</small></div>

                    <hr className="mt-6 border-b-1 border-blueGray-300" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    {error && <Alert color='danger'>{error}</Alert>}

                    <Formik
                        initialValues={{ email: '' }}
                        onSubmit={onSubmit}
                        component={LostPasswordForm}
                        validationSchema={validateLostPasswordForm}
                    />

                </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
                <div className="w-1/2">
                    <Link to={{ pathname: routes.HOME, search: window.location.search }} className="text-blueGray-200">

                        <small>S'identifier </small>
                    </Link>
                </div>
            </div>

        </div>
    )

}

export default LostPassword
