import React from 'react'

const NotFound: React.FC = () => {
  return (
    <div className='w-full lg:w-4/12 px-4'>
      <div className='relative flex flex-col min-w-0 break-words w-full pt-36 border-0 text-center text-white' style={{
        fontSize: '40px',
        textTransform: 'uppercase',
        fontWeight: 700,
        paddingTop: '60px'
      }}>
        404<br />Oup's !
      </div>
    </div>


  )
}

export default NotFound
