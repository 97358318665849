import React, { useMemo } from 'react'

interface IAlert extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children?: React.ReactNode
    color: 'primary' | 'danger' | 'warning' | 'success' | 'info'
    icon?: string
}

const Alert: React.FC<IAlert> = ({ children, color, icon }) => {

    const alertClass = useMemo(() => {


        const cl = [];


        cl.push('flex')
        cl.push('text-white')
        cl.push('px-6')
        cl.push('py-4')
        cl.push('border-0')
        cl.push('rounded')
        cl.push('relative')
        cl.push('mb-4')


        switch (color) {
            case 'danger':
                cl.push('bg-red-500')
                break;
            case 'info':
                cl.push('bg-blueGray-600')
                break;
        }

        return cl.join(' ');


    }, [color]);

    return (
        <div className={alertClass}>
            {icon &&
                <div className="text-xl align-middle" style={{ marginRight: 10 }}>
                    <i className={`fas fa-${icon}`}></i>
                </div>
            }
            <div className="align-middle">
                {children}
            </div>

        </div>
    )
}

export default Alert
