export interface IParams {
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  data?: any
}

export interface IIint {
  method: string,
  headers: any,
  body?: any
}

const r = async <T,>(params: IParams): Promise<T> => {


  return new Promise(async (resolve, reject) => {


    if (params.data && params.method === 'GET') {

      Object.keys(params.data).map((k) => {
        if (params.data[k] === null) {
          delete params.data[k];
        }
        return null;
      })

    }

    const myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    const myInit: IIint = {
      method: params.method.toUpperCase(),
      headers: myHeaders,
    };

    if (params.data && ['POST', 'PUT', 'PATCH'].includes(params.method)) {
      myInit.body = JSON.stringify(params.data)
    }
    if (params.data && ['GET'].includes(params.method)) {

      params.url = `${params.url}?${new URLSearchParams(params.data).toString()}`

    }


    const myRequest = new Request(params.url, myInit);

    fetch(myRequest, myInit)
      .then((response) => {

        if (response.ok) {

          const contentType = response.headers.get("content-type");
          if (contentType) {
            return response.json();
          } else {
            return response.text();
          }

        }

        throw response;

      })
      .then((data) => {

        resolve(data);

      }).catch((async (error: any) => {

        if (typeof error.text === 'function') {

          const errorResult = await error.text();
          const errorResultJson = JSON.parse(errorResult);

          if (errorResultJson.statusCode) {

            reject({
              code: errorResultJson.statusCode,
              message: errorResultJson.message,
            });
          }

        } else {

          reject(error);
        }

      }))
  });
}

export default r
