import React from 'react'
import { Routes, Route } from 'react-router-dom'
import NavContainer from './components/NavContainer'
import routes from './routes'
import Home from './sreens/Home'
import LostPassword from './sreens/LostPassword'
import NotFound from './sreens/NotFound'
import UpdateLostPassword from './sreens/UpdateLostPassword'


const Router: React.FC = () => {

  return (
    <>
      <Routes>
        <Route path={routes.HOME} element={<NavContainer children={<Home />} />} />
        <Route path={routes.LOST_PASSWORD} element={<NavContainer children={<LostPassword />} />} />
        <Route path={routes.UPDATE_LOST_PASSWORD} element={<NavContainer children={<UpdateLostPassword />} />} />

        {/* 404 Not Found */}
        <Route path="*" element={<NavContainer children={<NotFound />} />} />
      </Routes>
    </>
  )
}

export default Router
