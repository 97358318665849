import React, { InputHTMLAttributes } from 'react'
import classNames from 'classnames'
import FormError from '../../FormsLayout/FormError'
import { getError } from '../../../../utils/form'
import { CustomFieldProps } from '../../../../types/form.types'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: JSX.Element
}

const Input: React.FC<InputProps & CustomFieldProps> = ({ className = '', icon, field, form, ...props }) => {
  const error = getError({ form, field })

  return (
    <div className={`relative w-full ${icon ? `flex-wrap items-stretch mb-3` : ``}`}>


      <input
        type="text"
        className={classNames({
          input: true,
          'input-with-icon': icon,
          error,
          disabled: props.disabled,
          'border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150': true,
          [className]: true
        })}
        {...props}
        {...field}
      />


      {icon && <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-2 py-1">
        <i className={`fas fa-${icon} mt-2`}></i>
      </span>}
      {error && <FormError error={error} />}
    </div>
  )
}

export default Input
