import React, { useState } from 'react'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { Link } from 'react-router-dom'
import f from '../../utils/fetch';
import routes from '../../routes'
import LoginForm, { LoginFields } from '../../components/Forms/LoginForm'
import Loader from '../../components/Loader'
import Alert from '../../components/Alert';

const Home: React.FC = () => {


    const [pending, setPending] = useState<boolean>(false)
    const [identError, setIdentError] = useState<string | null>(null);


    const onSubmit = async (values: LoginFields, helpers: FormikHelpers<LoginFields>) => {

        if (pending) {
            return
        }

        setPending(true)
        try {
            const r: any = await f({ url: `/oauth2/authorization${window.location.search}`, method: 'POST', data: values });
            window.location.href = r.redirect;

        } catch (error: any) {

            setIdentError(error.message);
            helpers.resetForm()


        } finally {
            setPending(false)
        }

    }

    return (
        <div className='w-full lg:w-4/12 px-4'>
            <div className='relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0'>
                <div className="rounded-t mb-0 px-6 py-6">

                    <div style={{ fontSize: 60, textAlign: 'center', fontWeight: 900, fontFamily: 'Nunito' }} className="text-blueGray-600 text-center">
                        <img src="bleu-fond-blanc.png" style={{ width: 250, margin: 'auto' }} />
                    </div>
                    <div className="text-blueGray-400 text-center mb-3 font-bold"><small>Identification</small></div>
                    <hr className="mt-6 border-b-1 border-blueGray-300" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">

                    {pending && <Loader absolute />}
                    {identError && <Alert color='danger'>{identError}</Alert>}
                    <Formik initialValues={{ email: '', password: '' }} onSubmit={onSubmit}>
                        {(formikProps: FormikProps<LoginFields>) => <LoginForm {...formikProps} />}
                    </Formik>
                </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
                <div className="w-1/2">
                    <Link to={{ pathname: routes.LOST_PASSWORD, search: window.location.search }} className="text-blueGray-200">

                        <small>Mot de passe perdu ? </small>
                    </Link>
                </div>
            </div>

        </div>
    )

}

export default Home
