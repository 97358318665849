import React from 'react'
import { InfinitySpin } from 'react-loader-spinner'

interface LoaderProps {
  show?: boolean
  className?: string
  height?: number
  width?: number
  absolute?: boolean
}

const Loader: React.FC<LoaderProps> = ({ show = true, className = '', height = 50, width = 50, absolute = false }) => {
  if (!show) return <></>
  return (
    <div
      className={`text-center flex justify-center flex-grow items-center ${absolute ? `absolute top-0 bottom-0 left-0 right-0 z-50` : ``
        } ${className}`}
      style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
    >
      <InfinitySpin width='200' color="#1F2A3B" />
    </div>
  )
}

export default Loader
